import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';
import { GlobalService } from './global.service';

@Injectable()
export class MenuService {

  MENU_ITEM: any;
  constructor(
    private authService: AuthService,
    public _globalService: GlobalService,
    private _router: Router,
    private tokenStorage: TokenStorage) {
    this.MENU_ITEM = this.tokenStorage.decodeActions() || [];
    this.getNodePath(this.MENU_ITEM);
    const parentRout = this.authService.currentUser.isMerchant ? 'merchant' : 'admin';
    const children = {
      children: [
        {
          icon: 'users', path: `/${parentRout}/reports/`, title: 'Members', children: [
            { icon: 'address-card', path: `/${parentRout}/reports/members/registration`, title: 'Registered' },
            { icon: 'bell-slash', path: `/${parentRout}/reports/members/opt-out`, title: 'Opt Out' },
            { icon: 'tags', path: `/${parentRout}/reports/members/voucher`, title: 'Voucher' },
            { icon: 'tags', path: `/${parentRout}/reports/members/milestone-voucher`, title: 'Milestone Voucher' },
            { icon: 'tags', path: `/${parentRout}/reports/members/custom-voucher`, title: 'Custom Voucher' },
            { icon: 'tags', path: `/${parentRout}/reports/members/merchant-voucher`, title: 'Merchant Voucher' }
          ]
        },
        // { icon: 'address-card', path: `/${parentRout}/reports/registration`, title: 'Registered Members' },
        {
          icon: 'money', path: `/${parentRout}/reports`, title: 'Sales', children: [
            { icon: 'money', path: `/${parentRout}/reports/sales/transaction`, title: 'Member' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales/non-member-transaction`, title: 'Non Member' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales/non-member-transaction-converted`, title: 'Non Member Converted' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales/loyalty-conversion`, title: 'Loyalty Conversion' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales/visiting`, title: 'Visiting' },
            { icon: 'money', path: `/${parentRout}/reports/sales/other-members-transaction`, title: 'Other Merchant Transaction' },
          ]
        },
        {
          icon: 'exchange', path: `/${parentRout}/reports/sales-summary`, title: 'Sales Summary', children: [
            // { icon: 'exchange', path: `/${parentRout}/reports/sales-summary`, title: 'Sales Summary' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/sales-summary`, title: 'Sales Summary' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/new-member`, title: 'New Member' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/one-timer`, title: 'One Timer' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/repeat`, title: 'Repeat' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/non-member`, title: 'Non Member' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/daily-summary`, title: 'Daily Summary' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/mis`, title: 'MIS' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/kpi-summary`, title: 'KPI Summary' },
            { icon: 'exchange', path: `/${parentRout}/reports/sales-summary/voucher-summary`, title: 'Voucher Summary' }
          ]
        },
        {
          icon: 'money', path: `/${parentRout}/reports/`, title: 'ROI', children: [
            { icon: 'exchange', path: `/${parentRout}/reports/roi/campaign-performance`, title: 'Campaign' },
            { icon: 'exchange', path: `/${parentRout}/reports/roi/milestone-performance`, title: 'Milestone' },
            { icon: 'exchange', path: `/${parentRout}/reports/roi/goodwill-performance`, title: 'Goodwill' },
          ]
        },
      ],
      icon: 'file-text',
      path: '',
      title: 'Reports',
      toggle: 'init'
    };
    const storeMan = {
      icon: 'university',
      path: '',
      title: 'Store Management',
      children: [
        {
          'icon': 'list-alt',
          'path': '/merchant/stores/index',
          'title': 'Stores'
        }
      ]
    };

    /* const campaign = {
      icon: 'gift',
      path: '',
      title: 'Campaign Management',
      children: [
        {
          icon: 'list',
          path: '/merchant/campaign/list',
          title: 'Campaign List'
        },
        {
          icon: 'plus-circle',
          path: '/merchant/campaign/create',
          title: 'New Campaign'
        }
      ]
    }; */
    if (this.authService.currentUser.user_type_id === 4) {
      // this.MENU_ITEM.push(children, storeMan, campaign);
      this.MENU_ITEM.push(children, storeMan);
    }
    this.getNodePath(this.MENU_ITEM);
  }

  private parent_node = null;
  private node = null;
  private path_item = [];

  protected queryParentNode(json: any, node_id: any) {
    for (let i = 0; i < json.length; i++) {
      if (this.node) {
        break;
      }
      const object = json[i];
      if (!object || !object.path) {
        continue;
      }
      if (object.path === node_id) {
        this.node = object;
        break;
      } else {
        if (object.children) {
          this.parent_node = object;
          this.queryParentNode(object.children, node_id);
        } else {
          continue;
        }
      }
    }
    if (!this.node) {
      this.parent_node = null;
    }
    return {
      parent_node: this.parent_node,
      node: this.node
    };
  }

  protected creatRouterLink(nodeId: any) {
    this.node = null;
    this.parent_node = null;
    const menuObj = this.queryParentNode(this.MENU_ITEM, nodeId);
    if (menuObj.parent_node && menuObj.parent_node.path) {
      this.path_item.unshift(menuObj.parent_node.path);
      return this.creatRouterLink(menuObj.parent_node.path);
    } else {
      return this.path_item;
    }
  }

  protected getNodePath(json: any): void {
    json.forEach((index) => {
      if (index.children) {
        // delete index.routerLink;
        this.getNodePath(index.children);
        index.toggle = 'init';
      } else {
        this.path_item = [index.path];
        index.routerLink = this.creatRouterLink(index.path);
        // index.routerLink.unshift('/', 'dashboard');
      }
    });
  }

  public putSidebarJson() {
    return this.MENU_ITEM;
  }

  public selectItem(item) {
    item.forEach(element => {
      if (element.routerLink) {
        element.isActive = this._router.isActive(this._router.createUrlTree(element.routerLink), true);
        if (element.isActive) {
          // this._globalService._isActived(element);
          this._globalService.dataBusChanged('isActived', element);
        }
      } else if (element.children) {
        this.selectItem(element.children);
      }
    });
  }

}
